<template>
  <div class="home">
    <div style="
          height: 20px;
          line-height: 60px;
          right: 42px;
          position: fixed;
          text-align: right;">
      <i @click="handleSelect('/headCategory/headShop')" class="el-icon-close"></i>
    </div>

    <div class="pt20"></div>
    <div class="form">
      <h2>添加数据</h2>
      <el-form ref="form" :required="true" :model="form" label-width="180px">

        <el-form-item :required="true" label="头像:">
          <div class="block" v-for="(img, index) in list" :key="img.id">
            <i @click.stop="deleimage(index)" class="el-icon-close imgii"></i>
            <el-image
              style="width: 150px; height: 150px"
              :src="img.src"
              fit="fill">
            </el-image>
          </div>

          <input ref="filElem" type='file' name='file[]' hidden class='upload-file' @change='getFile' />
          
          <div v-if='list.length == 0' @click='choiceImg' class='el-upload el-upload--picture-card'>
            <i style='margin-top: 55px;margin-left: 10px;' class='el-icon-plus'></i>
          </div>
        </el-form-item>

        <el-form-item :required="true" label="英文头像名称">
          <el-input v-model="form.en_head_name"></el-input>
        </el-form-item>

        <el-form-item :required="true" label="中文头像名称">
          <el-input v-model="form.head_name"></el-input>
        </el-form-item>

        <el-form-item label="英文简介">
          <el-input v-model="form.en_remark"></el-input>
        </el-form-item>

        <el-form-item label="中文简介">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>

        <el-form-item :required="true" label="分类ID">
          <el-select v-model.number="form.category_id" placeholder="请选择分类">
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.category_name"
              :value="category.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="期限和价格">
          <div v-for="(item, index) in ageingOptions" :key="index" class="ageing-item">
            <span style=" white-space: nowrap;">{{ item.label }}</span>
            <el-input v-model.number="form.ageing[index].price" placeholder="不填就是免费"></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
          <el-button @click="goNav('/headCategory/headShop')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="pt20"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  data() {
    return {
      form: {
        head_img: '', // 用于存储头像URL
        en_head_name: '',
        head_name: '',
        en_remark: '',
        remark: '',
        category_id: null,
        ageing: [
          { ageing: 1, price: '' }, // 1个月
          { ageing: 3, price: '' }, // 3个月
          { ageing: 0, price: '' }  // 永久
        ] // 默认值
      },
      list: [], // 存储上传的图片信息
      categories: [],
      ageingOptions: [
        { label: '1个月', value: 1 },
        { label: '3个月', value: 3 },
        { label: '永久', value: 0 }
      ]
    }
  },
  methods: {
    handleSelect(url) {
      this.$router.push(url)
    },
    onSubmit() {
      let _this = this;

      if (_this.list.length === 0) {
        _this.$message.error('请上传头像');
        return;
      }

      // 提交头像
      let formdata = new FormData();
      formdata.append("file", _this.list[0].file); // 限制只上传一张图片

      axios.post(config.uploadHeadimg, formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(function (response) {
          if (response.data.code === 200) {
            _this.form.head_img = response.data.data; // 获取返回的头像URL

            // 将 ageing 转换为 JSON 字符串
            const ageingArray = _this.form.ageing;
            const allPricesEmpty = ageingArray.every(item => item.price === '');
            const ageingJson = allPricesEmpty ? '' : JSON.stringify(ageingArray);

            // 提交表单数据到添加接口
            return axios.put(config.headShop, { ..._this.form, ageing: ageingJson });
          } else {
            _this.$message.error(response.data.message);
          }
        })
        .then(function (response) {
          if (response.data.code === 200) {
            _this.$message({
              message: '添加成功',
              type: 'success'
            });
            _this.goNav('/headCategory/headShop');
          }
        })
        .catch(function (error) {
          console.log(error);
          _this.$message.error('操作失败，请重试');
        });
    },
    deleimage(index) {
      this.list.splice(index, 1); // 删除指定的图片
    },
    choiceImg() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click')); // 点击文件输入框
    },
    getFile() {
      const that = this;
      let files = this.$refs.filElem.files; // 获取选择的文件

      if (files.length > 1) {
        this.$message.error('只能上传一张图片');
        return;
      }

      let inputFile = files[0]; // 获取第一个文件

      if (inputFile) {
        // 检查文件类型
        if (inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif') {
          console.log('上传文件中有格式不对');
          return;
        }

        const reader = new FileReader();
        
        reader.readAsDataURL(inputFile); // 将文件读取为Data URL
        
        reader.onload = function () {
          let id = new Date().getTime(); // 使用时间戳作为唯一ID
          
          that.list.push({
            id,
            src: this.result, // 获取读取的结果
            file: inputFile, // 存储文件对象
          });
        }
      }
    },
    goNav(url) { 
      this.$router.push(url)
    },
    fetchCategories() {
      axios.get(config.headCategory, { params: { page: 0, query: '' } })
        .then(response => {
          this.categories = response.data.data.data; // 获取分类列表
        })
        .catch(error => {
          console.error('获取分类失败:', error);
          this.$message.error('获取分类失败，请重试');
        });
    }
  },
  created() {
    this.fetchCategories(); // 在组件创建时获取分类列表
  }
}
</script>

<style scoped>
.form {
  width: 500px;
  margin-left: 200px;
}
.el-icon-close:before {
  font-size: 24px;
}
.ageing-item {
  display: flex;
  align-items: center;
}
.ageing-item span {
  margin-right: 10px;
}
.block {
  position: relative;
}
.imgii {
  position: absolute;
  right: -10px;
}
</style>